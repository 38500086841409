import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BackButton from './BackButton';
import FriendsModal from './FriendsModal';
import WalletModal from './WalletModal';
import TasksModal from './TaskModal';
import { getUserByChatId, sendDonationNotification } from '../services/userService';
import { THEME, TonConnectUIProvider, useTonConnectUI, useTonAddress, useTonConnectModal } from '@tonconnect/ui-react';

const MainScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: #fff;
  width: 100%;
  font-family: 'Jost', sans-serif;
  overflow: hidden;
  touch-action: none;
`;

const Header = styled.div`
  display: flex;
  width: 90%;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const LogoImage = styled.img`
  width: 20vh;
  height: 20vh;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 0;
  margin-top: 0;
  font-family: Inter;
`;

const ButtonContainer = styled.div`
  position: relative;
  width: 90%;
  margin: 0.4rem 0;
  border-radius: 10px;
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: ${props => (props.primary ? '#ff851b' : props.black ? '#000' : '#2d606c')};
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  font-family: "Inter";
  font-weight: Semi Bold;
  position: relative;
  z-index: 1;
  white-space: normal; /* Allows text to wrap */
  margin-bottom: 0; /* Ensure consistent margin */
`;

const ButtonTonText = styled(Button)`
  background-color: #EF8332;
  color: black;
`;

const ButtonStrip = styled.div`
  width: 100%;
  height: 3vh;
  background-color: #43717c;
  border-radius: 0 0 15px 15px;
  position: absolute;
  bottom: -0.7vh;
  left: 0;
  z-index: 0;
`;

const ButtonTonStrip = styled.div`
  width: 100%;
  height: 3vh;
  background-color: #EF8332;
  border-radius: 0 0 15px 15px;
  position: absolute;
  bottom: -0.7vh;
  left: 0;
  z-index: 1;
`;

const ConnectWalletButton = styled(Button)`
  background-color: ${props => (props.connected ? 'rgba(111, 46, 24, 0.41)' : 'rgba(239, 131, 50, 0.56)')};
  color: ${props => (props.connected ? '#EF8332' : 'black')};
  border: 1px solid ${props => (props.connected ? 'rgba(111, 46, 24, 1)' : 'rgba(239, 131, 50, 1)')};
`;

const MainScreen = ({ chatId, language }) => {
  const navigate = useNavigate();
  const [isFriendsModalOpen, setIsFriendsModalOpen] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [username, setUsername] = useState('');
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonAddress();
  const { open } = useTonConnectModal();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log('Fetching user data for chatId:', chatId);
        const data = await getUserByChatId(chatId);
        setReferralCode(data.referralCode);
        setUsername(data.username);
        console.log('User data fetched:', data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [chatId]);

  const handleLeaderboardClick = () => {
    console.log('Navigating to leaderboard');
    navigate('/leaderboard');
  };

  const handleAboutGameClick = () => {
    console.log('Navigating to about game');
    navigate('/about-game');
  };

  const handleSendTransaction = async () => {
    if (!wallet) {
      console.log('No wallet connected');
      return;
    }

    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60,
      messages: [
        {
          address: "UQCH27pPReIvLzUcLkwniNbP6p9lMSHTN3nyFKWcXCaGnbka",
          amount: "1000000000", // 1 TON
        }
      ]
    };

    try {
      console.log('Sending transaction:', transaction);
      await tonConnectUI.sendTransaction(transaction);
      console.log('Transaction sent, sending notification');
      await sendDonationNotification(chatId, username, wallet);
      console.log('Notification sent');
    } catch (error) {
      console.error('Error sending transaction or notification:', error);
    }
  };

  const handleDonate = () => {
    if (!wallet) {
      console.log('No wallet connected, opening connect modal');
      open();
    } else {
      handleSendTransaction();
    }
  };

  const handleConnectWallet = () => {
    if (wallet) {
      console.log('Wallet already connected, opening wallet modal');
      setIsWalletModalOpen(true);
    } else {
      console.log('No wallet connected, opening connect modal');
      open();
    }
  };

  const handleDisconnectWallet = () => {
    console.log('Disconnecting wallet');
    tonConnectUI.disconnect();
    setIsWalletModalOpen(false);
  };

  const shortenAddress = (address) => {
    if (!address) return '';
    const formattedAddress = address.includes(':') ? address.split(':')[1] : address;
    return `${formattedAddress.slice(0, 4)}...${formattedAddress.slice(-4)}`;
  };

  return (
    <TonConnectUIProvider
      manifestUrl="https://raw.githubusercontent.com/daanicccch/tonconnect-manifest.json/main/tonconnect-manifest.json"
      uiPreferences={{ theme: THEME.DARK }}
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/DemoDappWithTonConnectBot/demo'
      }}
    >
      <MainScreenContainer>
        <Header>
          <BackButton />
        </Header>
        <Logo>
          <LogoImage src="/menu-page/logo.png" alt="EcoHero Logo" />
          <Title>EcoHero</Title>
        </Logo>
        <ButtonContainer>
          <Button onClick={() => setIsTasksModalOpen(true)}>
            {language === 'ru' ? 'Задания' : 'Tasks'}
          </Button>
          <ButtonStrip />
        </ButtonContainer>
        <ButtonContainer>
          <Button onClick={handleLeaderboardClick}>
            {language === 'ru' ? 'Таблица лидеров' : 'Leaderboard'}
          </Button>
          <ButtonStrip />
        </ButtonContainer>
        <ButtonContainer>
          <Button onClick={() => setIsFriendsModalOpen(true)}>
            {language === 'ru' ? 'Друзья' : 'Friends'}
          </Button>
          <ButtonStrip />
        </ButtonContainer>
        <ButtonContainer>
          <Button onClick={handleAboutGameClick}>
            {language === 'ru' ? 'Об игре' : 'About the Game'}
          </Button>
          <ButtonStrip />
        </ButtonContainer>
        <ButtonContainer>
          <ButtonTonText onClick={handleDonate}>
            {language === 'ru' ? 'Донат 1 TON' : 'Donate 1 TON'}
          </ButtonTonText>
          <ButtonTonStrip />
        </ButtonContainer>
        <ButtonContainer>
          {wallet ? (
            <ConnectWalletButton connected onClick={handleConnectWallet}>
              {shortenAddress(wallet)}
            </ConnectWalletButton>
          ) : (
            <ConnectWalletButton onClick={handleConnectWallet}>
              {language === 'ru' ? 'Привязать кошелек' : 'Connect Wallet'}
            </ConnectWalletButton>
          )}
        </ButtonContainer>
        <FriendsModal
          isOpen={isFriendsModalOpen}
          onClose={() => setIsFriendsModalOpen(false)}
          chatId={chatId}
          referralCode={referralCode}
          language={language}
        />
        <TasksModal
          isOpen={isTasksModalOpen}
          onClose={() => setIsTasksModalOpen(false)}
          chatId={chatId}
          language={language}
        />
        {isWalletModalOpen && (
          wallet && (
          <WalletModal
            wallet={wallet}
            language={language}
            onClose={() => setIsWalletModalOpen(false)}
            onDisconnect={handleDisconnectWallet}
          />
          )
        )}
      </MainScreenContainer>
    </TonConnectUIProvider>
  );
};

export default MainScreen;
