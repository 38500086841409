import { updateUserBalanceAndEcha, getUserByChatId } from './userService';

const loadCdTmaScript = () => {
  return new Promise((resolve, reject) => {
    if (window.cdTmaShowAd) {
      resolve(window.cdTmaShowAd);
    } else {
      const script = document.createElement('script');
      script.src = 'https://js.canstrm.com/in-stream-ad-admanager/tma.js';
      script.async = true;
      script.onload = async () => {
        if (window.initCdTma && !window.cdTmaShowAd) {
          window.cdTmaShowAd = await window.initCdTma({ id: "6029413" })
          resolve(window.cdTmaShowAd );
        } else {
          reject(new Error('Не удалось загрузить CdTma SDK'));
        }
      };
      script.onerror = () => reject(new Error('Не удалось загрузить CdTma SDK'));
      document.head.appendChild(script);
    }
  });
};

const initializeAdController = async () => {
  // try {
    const CdTma = await loadCdTmaScript();
    // CdTma.setDebugMode(false); // тестовый режим
    // CdTma.init("50e6e52eb40198b4b0415418f8aac2b9"
    // {
    //   app_key: '50e6e52eb40198b4b0415418f8aac2b9',
    //   init_data: window.Telegram.WebApp.initData,
    //   init_data_unsafe: window.Telegram.WebApp.initDataUnsafe
    // }
    // );
    return CdTma;
  // } catch (error) {
  //   if (error instanceof Error) {
  //     throw new Error(error.message || 'Неизвестная ошибка');
  //   } else {
  //     throw new Error('Неизвестная ошибка');
  //   }
  // }
};

// Показ рекламы и обработка результата
const handleShowCdAd = async (chatId, language, setAnimate, navigate) => {
  try {
    const CdTma = await initializeAdController();

    console.log('try show cdtma')

    await CdTma()
        let amount;
        amount = (Math.random() * (0.0042 - 0.0029) + 0.0029).toFixed(4);
        const echaCoins = 5000;

        await updateUserBalanceAndEcha(chatId, parseFloat(amount), echaCoins);

        // Получение текущего количества echaCoins после обновления
        const updatedData = await getUserByChatId(chatId);
        const updatedEchaCoins = parseFloat(updatedData.echaCoins);

        console.log(`Ad success - updated EchaCoins: ${updatedEchaCoins}`); // Log updated value
        if (navigate && typeof navigate === 'function') {
          setAnimate(true); // Set animate to true
          navigate('/', { state: { refresh: true, updatedEchaCoins: updatedEchaCoins } });
        } else {
          console.error('Navigate is not a function');
        }
        return true
  } catch (error) {
      console.error(error)
      return false
  }
};

export { handleShowCdAd };
