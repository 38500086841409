import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { checkSubscription, getTasks, getUserTasks, completeTask, getUserByChatId, sendDonationNotification } from '../services/userService'; // Import functions
import { THEME, TonConnectUIProvider, useTonConnectUI, useTonWallet, useTonConnectModal, useTonAddress } from '@tonconnect/ui-react';

const ModalOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/main-page/background.png');
  background-size: cover;
  background-position: center;  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: #0C091A;
  border-radius: 20px 20px 0 0;
  width: 100%;
  color: #fff;
  font-family: "Inter";
  font-weight: Regular;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  &.scrolled::before {
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6vh;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    z-index: 10;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
`;

const Logo = styled.div`
  height: 9vh;
  width: 9vh;
  background-image: url('/task-page/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 1.8rem;
  left: 1rem;
  z-index: 1001;
`;

const MenuIcon = styled.div`
  height: 8vh;
  width: 8vh;
  background-image: url('/task-page/menu.png');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 1.8rem;
  right: 1rem;
  z-index: 1001;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 20;
  width: 6vh;
  height: 6vh;
  background-image: url('/task-page/back.png');
  background-size: cover;   
  background-repeat: no-repeat;
`;

const TaskButton = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: #2d606c;
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.completed {
    text-decoration: line-through;
    color: #9e9e9e;
  }
`;

const TaskInfo = styled.div`
  display: flex;
  align-items: center;
`;

const TaskCircle = styled.div`
  width: 5vh;
  height: 5vh;
  margin-left: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.completed {
    background-image: url('/task-page/image.png');
  }

  &.uncompleted {
    background-image: url('/task-page/sd.png');
  }
`;

const JoinButton = styled.button`
  background: #ef8332;
  border: none;
  border-radius: 15px;
  padding: 1.3rem;
  color: black;
  cursor: pointer;
  width: 90%;
  font-size: 1rem;
  margin-top: 0.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const SuccessButton = styled.button`
  background: transparent;
  border: 2px solid rgba(111, 46, 24, 1);
  border-radius: 15px;
  padding: 1rem;
  color: #EF8332;
  width: 100%;
  font-size: 1rem;
  margin-top: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const AddTaskButton = styled.button`
  background: #ef8332;
  border: none;
  border-radius: 15px;
  padding: 0.8rem; 
  color: black;
  cursor: pointer;
  width: 50%;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const HorizontalLine = styled.hr`
  width: 90%;
  border: none;
  border-top: 1px solid #3d3a47;
  margin: 1rem auto;
`;

const StyledScrollbars = styled(Scrollbars)`
  .thumb-vertical {
    border-radius: 6px;
  }
`;

const ModalHeader = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 600; /* Semi Bold */
  margin-left: 3vh;
  margin-top:1vh;
`;

const ModalParagraph = styled.p`
  font-family: "Inter";
  font-weight: "Regular";
  margin-left: 3vh;
`;

const ModalSuccessText = styled.span`
  font-family: "Inter";
  font-weight: "Regular";
  text-decoration: line-through;
`;

const ModalSuccessParagraph = styled.p`
  font-family: "Inter";
  font-weight: "Regular";
  margin-left: 3vh;
`;

const CenteredText = styled.p`
  text-align: center;
  font-family: "Inter";
  font-weight: "Regular";
  font-size: 16px;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const TelegramLink = styled.a`
  color: #EF8332;
  text-decoration: none;
`;

const SupportText = styled.p`
  text-align: center;
  font-family: "Inter";
  font-weight: "Regular";
  font-size: 16px;
  margin: 0.5rem 0 0.5rem 0;
`;

const TaskModal = ({ task, setSelectedTask, setIsSuccessModalOpen, handleJoinChannel, handleStartBotTask, language }) => {
  const handleClickOutside = () => {
    setSelectedTask(null);
    setIsSuccessModalOpen(false);
  };

  return (
    <ModalOverlay onClick={handleClickOutside}>
      <Logo />
      <MenuIcon />
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={handleClickOutside} />
        <div style={{ padding: '1rem' }}>
          <ModalHeader>{task.name}</ModalHeader>
          <ModalParagraph>
            {task.type === 'subscribe'
              ? (language === 'ru' 
                ? <>
                    Подпишитесь на канал<br />
                    Награда {task.echaCoins} ECHA
                  </>
                : <>
                    Subscribe to the channel<br />
                    Reward {task.echaCoins} ECHA
                  </>)
              : (language === 'ru' 
                ? <>
                    Запустите бота и начните играть<br />
                    Награда {task.echaCoins} ECHA
                  </>
                : <>
                    Start the bot and begin playing<br />
                    Reward {task.echaCoins} ECHA
                  </>)
            }
          </ModalParagraph>
          {task.type === 'subscribe' ? (
            <JoinButton onClick={() => handleJoinChannel(task._id, task.link)}>
              {language === 'ru' ? 'Вступить' : 'Join'}
            </JoinButton>
          ) : (
            <JoinButton onClick={() => handleStartBotTask(task._id, task.link)}>
              {language === 'ru' ? 'Перейти' : 'Start'}
            </JoinButton>
          )}
        </div>
      </ModalContainer>
    </ModalOverlay>
  );
};

const SuccessModal = ({ task, setSelectedTask, setIsSuccessModalOpen, language }) => {
  const handleClickOutside = () => {
    setSelectedTask(null);
    setIsSuccessModalOpen(false);
  };

  return (
    <ModalOverlay onClick={handleClickOutside}>
      <Logo />
      <MenuIcon />
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={handleClickOutside} />
        <div style={{ padding: '1rem' }}>
          <ModalHeader>{task.name}</ModalHeader>
          <ModalSuccessParagraph>
            <ModalSuccessText>
              {task.type === 'subscribe' 
                ? (language === 'ru' ? 'Подпишитесь на канал' : 'Subscribe to the channel')
                : (language === 'ru' ? 'Запустите бота и начните играть' : 'Start the bot and begin playing')}
            </ModalSuccessText><br />{language === 'ru' ? `Награда ${task.echaCoins} ECHA` : `Reward ${task.echaCoins} ECHA`}
          </ModalSuccessParagraph>
          <SuccessButton>✅ {language === 'ru' ? 'Выполнено' : 'Completed'}</SuccessButton>
        </div>
      </ModalContainer>
    </ModalOverlay>
  );
};

const TasksPage = ({ isOpen, onClose, chatId, language }) => {
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [username, setUsername] = useState('');
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const walletAddress = useTonAddress();
  const { open } = useTonConnectModal();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await getUserByChatId(chatId);
        setUsername(data.username);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (chatId) {
      fetchUserData();
    }
  }, [chatId]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const fetchedTasks = await getTasks();
        const userTasks = await getUserTasks(chatId);
        setTasks(fetchedTasks);
        setCompletedTasks(userTasks.filter(task => task.completed).map(task => task._id));
      } catch (error) {
        console.error('Ошибка при получении задач:', error);
      }
    };

    if (isOpen) {
      fetchTasks();
    }
  }, [isOpen, chatId]);

  const handleTaskClick = (task) => {
    if (completedTasks.includes(task._id)) {
      return;
    }
    setSelectedTask(task);
  };

  const handleJoinChannel = (taskId, link) => {
    window.Telegram.WebApp.openTelegramLink(`https://t.me/${link}`);

    setTimeout(async () => {
      try {
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
        const result = await checkSubscription(userId, '@' + link);

        if (result.isMember) {
          await completeTask(userId, taskId);
          setTasks((prevTasks) =>
            prevTasks.map((task) =>
              task._id === taskId ? { ...task, completed: true } : task
            )
          );
          setCompletedTasks(prevTasks => [...prevTasks, taskId]);
          setSelectedTask((prevTask) => ({ ...prevTask, completed: true }));
          setIsSuccessModalOpen(true);
        } 
      } catch (error) {
        console.error('Ошибка при проверке подписки:', error);
      }
    }, 3000);
  };

  const handleStartBotTask = (taskId, botLink) => {
    window.Telegram.WebApp.openTelegramLink(`https://t.me/${botLink}`);

    setTimeout(async () => {
      try {
        await completeTask(chatId, taskId);
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task._id === taskId ? { ...task, completed: true } : task
          )
        );
        setCompletedTasks(prevTasks => [...prevTasks, taskId]);
        setSelectedTask((prevTask) => ({ ...prevTask, completed: true }));
        setIsSuccessModalOpen(true);
      } catch (error) {
        console.error('Ошибка при завершении задачи:', error);
      }
    }, 3000);
  };

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    setIsScrolled(scrollTop > 0);
  };

  const handleSendTransaction = async () => {
    if (!wallet) {
      console.log('No wallet connected');
      return;
    }

    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60,
      messages: [
        {
          address: "UQCH27pPReIvLzUcLkwniNbP6p9lMSHTN3nyFKWcXCaGnbka",
          amount: "1000000000", // 1 TON
        }
      ]
    };

    try {
      console.log('Sending transaction:', transaction);
      await tonConnectUI.sendTransaction(transaction);
      console.log('Transaction sent, sending notification');
      await sendDonationNotification(chatId, username, walletAddress);
      console.log('Notification sent');
    } catch (error) {
      console.error('Error sending transaction or notification:', error);
    }
  };

  const handleDonateTonClick = () => {
    if (!wallet) {
      console.log('No wallet connected, opening connect modal');
      open();
    } else {
      handleSendTransaction();
    }
  };

  if (!isOpen) return null;

  const sortedTasks = tasks.sort((a, b) => {
    if (completedTasks.includes(a._id) && !completedTasks.includes(b._id)) {
      return 1;
    }
    if (!completedTasks.includes(a._id) && completedTasks.includes(b._id)) {
      return -1;
    }
    return 0;
  });

  return (
    <>
    <TonConnectUIProvider
      manifestUrl="https://raw.githubusercontent.com/daanicccch/tonconnect-manifest.json/main/tonconnect-manifest.json"
      uiPreferences={{ theme: THEME.DARK }}
      
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/DemoDappWithTonConnectBot/demo'
      }}
    >
      <ModalOverlay onClick={onClose}>
        <Logo />
        <MenuIcon />
        <ModalContainer className={isScrolled ? 'scrolled' : ''} onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={onClose} />
          <StyledScrollbars autoHide style={{ width: '100%', height: '83vh' }} onScroll={handleScroll}>
            <div style={{ padding: '0 1rem 0 1rem' }}>
              <ModalHeader style={{ marginTop: '1rem' }}>
                {language === 'ru' ? 'Задания' : 'Tasks'}
              </ModalHeader>
              {sortedTasks.map((task) => (
                <TaskButton
                  key={task._id}
                  className={completedTasks.includes(task._id) ? 'completed' : ''}
                  onClick={() => handleTaskClick(task)}
                >
                  {task.name}
                  <TaskCircle className={completedTasks.includes(task._id) ? 'completed' : 'uncompleted'} />
                </TaskButton>
              ))}
              <CenteredText>
                {language === 'ru' 
                  ? <>
                      Хотите разместить свое задание?<br />
                      Напишите нам <TelegramLink href="https://t.me/shtirbets">@shtirbets</TelegramLink>
                    </>
                  : <>
                      Want to post your task?<br />
                      Contact us <TelegramLink href="https://t.me/shtirbets">@shtirbets</TelegramLink>
                    </>
                }
              </CenteredText>
              <HorizontalLine />
              <SupportText>
                {language === 'ru' ? 'Поддержите проект' : 'Support the project'}
              </SupportText>
              <AddTaskButton onClick={handleDonateTonClick}>
                🎁 {language === 'ru' ? 'Донат 1 TON' : 'Donate 1 TON'}
              </AddTaskButton>
            </div>
          </StyledScrollbars>
        </ModalContainer>
      </ModalOverlay>
      </TonConnectUIProvider>
      {selectedTask && !selectedTask.completed && (
        <TaskModal 
          task={selectedTask} 
          setSelectedTask={setSelectedTask} 
          setIsSuccessModalOpen={setIsSuccessModalOpen} 
          handleJoinChannel={handleJoinChannel} 
          handleStartBotTask={handleStartBotTask} 
          language={language}
        />
      )}
      {isSuccessModalOpen && selectedTask && selectedTask.completed && (
        <SuccessModal 
          task={selectedTask} 
          setSelectedTask={setSelectedTask} 
          setIsSuccessModalOpen={setIsSuccessModalOpen} 
          language={language}
        />
      )}
    </>
  );
};

export default TasksPage;
